import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getSelfMember } from '../../services/members';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const wixCodeApi = controllerConfig.wixCodeApi;
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  const path = wixCodeApi.location.path;
  const isProductEdit = path.length > 1;
  const productId = isProductEdit ? path[1] : null;
  const navigateToFeed = () => {
    let url = wixCodeApi.location.baseUrl + '/feedpage';

    // Hack for demo
    if (wixCodeApi.location.baseUrl.indexOf('wix-bazaar') > -1) {
      url = wixCodeApi.location.baseUrl + '/all-items';
    }
    wixCodeApi.location?.to!(url);
  };
  return {
    async pageReady() {
      controllerConfig.setProps({
        navigateToFeed,
        instance: flowAPI.controllerConfig.appParams.instance,
        productId,
      });
      // Here you can pass props to the Widget
    },
  };
};

export default createController;
